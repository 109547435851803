import {useNavigate, useParams} from "react-router-dom";
import {FC, useContext, useEffect, useState} from "react";
import {BASE_URL} from "../../../services/constants";
import {getUser, postUser} from "./fetchers";
import {UserProps, UserType} from "./type";
import UserForm from "../../../components/Forms/UserForm/UserForm";
import {AuthContext} from "../../../context/AuthContext";

const User: FC<UserProps> = ({ mode="create" }) => {
    const {setNotification} = useContext(AuthContext);
    const [user, setUser] = useState<UserType>({} as UserType);
    const navigate = useNavigate();
    const {id} = useParams();

    useEffect(() => {
        if (id) {
            (async () => {
                setUser(await getUser(id));
            })()
        }
    }, [id])

    const getUrl = () => {
        if (mode === "create") {
            return `${BASE_URL}/user/create/`
        }
        return `${BASE_URL}/user/${id}/update/`
    }

    const onSubmit = async (data: UserType) => {
        const url = getUrl();
        try {
            await postUser(data, url);
            setNotification({
                type: "success",
                open: true,
                message: `User was successfully ${mode === "create" ? "created" : "edited"}`
            })
            navigate("/users");
        } catch (err) {
            setNotification({
                type: "error",
                open: true,
                message: `Error occurred while ${mode === "create" ? "creating" : "editing"} user`
            })
            console.log("Error creating related search");
        }
    }

    const formData = mode !== "create" && Object.keys(user).length > 0
        ? user as UserType
        : undefined

    return (
        <>
            <UserForm mode={mode} formData={formData} onSubmit={onSubmit} />
        </>
    )
}

export default User;