import { Box, Button, Checkbox, Typography } from "@mui/material";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import dayjs from "dayjs";
import { IMetric, IRequests } from "../../index";
import RemoveBadge from "../../../../../components/Badges/RemoveBadge";
import AddBadge from "../../../../../components/Badges/AddBadge";
import {Header, Row} from "../../../../../components/TableConstructor/types";

interface ILineGraph {
    loading: boolean;
    rows: Row[];
    headers?: Header[] | any;
    requests: IRequests;
    groupingLabel: IMetric;
}

const LineGraphRecharts = ({ loading = false, rows = [], headers = [], requests, groupingLabel }: ILineGraph) => {
    const [metrics, setMetrics] = useState<IMetric[]>([]);
    const [isShowGraph, setIsShowGraph] = useState(false);
    const [isOpenMetrics, setIsOpenMetrics] = useState(false);
    const [activeMetrics, setActiveMetrics] = useState<IMetric[]>([]);
    const [groupingParams, setGroupingParams] = useState<string[]>([]);
    const [activeGroupParams, setActiveGroupParams] = useState<string[]>([]);
    const [data, setData] = useState([]);
    const [isLegend, setIsLegend] = useState(false);

    const getRandomColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

    useEffect(() => {
        if (rows.length > 0) {
            setMetrics(requests.metrics);
            setActiveMetrics([requests.metrics[0]]);
        }
    }, [rows]);

    useEffect(() => {
        const groups: string[] = [];
        if (!groupingLabel) {
            groups.push("-");
        } else {
            for (let i = 0; i < rows.length; i++) {
                if (!groups.includes(rows[i][groupingLabel.key])) {
                    groups.push(rows[i][groupingLabel.key]);
                }
            }
        }
        setGroupingParams(groups);
        if (groups.length > 0) {
            setActiveGroupParams([groups[0]]);
        }
    }, [groupingLabel]);

    useEffect(() => {
        if (isShowGraph) {
            createDataForGraph();
        }
    }, [activeMetrics, isShowGraph, activeGroupParams, rows]);

    const handleOpenGraph = () => {
        setIsShowGraph(true);
    };

    const handleClickMetric = (metric: IMetric) => {
        let newMetrics: IMetric[];
        if (activeMetrics.findIndex(item => item.key === metric.key) !== -1) {
            newMetrics = activeMetrics.filter(item => item.key !== metric.key);
        } else {
            newMetrics = [...activeMetrics, metric];
        }
        setActiveMetrics(newMetrics);
    };

    const handleClickGrouping = (group: string) => {
        let newGroupingParams: string[];
        if (activeGroupParams.includes(group)) {
            newGroupingParams = activeGroupParams.filter(item => item !== group);
        } else {
            newGroupingParams = [...activeGroupParams, group];
        }
        setActiveGroupParams(newGroupingParams);
    };
    const createDataForGraph = () => {
        const newData: any = {};
        let xAxis = "date";
        for (let i = 0; i < requests.groups.length; i++) {
            const date = requests.groups[i].key;
            if (date === "date") {
                xAxis = date;
            } else if (date === "week") {
                xAxis = date;
            } else if (date === "date__month") {
                xAxis = date;
            } else if (date === "date__year") {
                xAxis = date;
            }
        }
        for (let i = 0; i < rows.length; i++) {
            if (!newData[rows[i][xAxis]]) {
                newData[rows[i][xAxis]] = {
                    xAxis: xAxis === "date" ? dayjs(rows[i][xAxis]).format("MMM, D") : rows[i][xAxis],
                };
            }
            for (let j = 0; j < activeMetrics.length; j++) {
                if (!groupingLabel) {
                    newData[rows[i][xAxis]] = {
                        ...newData[rows[i][xAxis]],
                        [`${activeMetrics[j].name} | -`]: rows[i][activeMetrics[j].key],
                    };
                } else if (activeGroupParams.includes(rows[i][groupingLabel.key])) {
                    newData[rows[i][xAxis]] = {
                        ...newData[rows[i][xAxis]],
                        [`${activeMetrics[j].name} | ${rows[i][groupingLabel.key]}`]: rows[i][activeMetrics[j].key],
                    };
                }
            }
        }
        setData(Object.values(newData));
    };
    return isShowGraph ? (
        <Box
            sx={{
                boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
                background: "#fff",
                p: ".75rem",
                minHeight: "545px",
                display: "flex",
                flexDirection: "column",
                gap: ".75rem",
                mb: ".75rem",
                opacity: loading ? ".5" : "1",
                pointerEvents: loading ? "none" : "auto",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                }}
            >
                <Box
                    sx={{
                        alignSelf: "baseline",
                        display: "flex",
                        gap: ".5rem",
                        alignItems: "center",
                    }}
                >
                    <AutoGraphIcon />
                    <Typography
                        sx={{
                            fontSize: "1rem",
                            fontWeight: "500",
                        }}
                    >
                        Graph
                    </Typography>
                    <Box onClick={() => setIsShowGraph(false)}>
                        <RemoveBadge />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        // flexWrap: "wrap"
                    }}
                >
                    <Button
                        sx={{ p: "9px 18px 9px 24px", minWidth: "auto" }}
                        endIcon={
                            isOpenMetrics ? (
                                <ExpandLessIcon
                                    style={{
                                        width: "24px",
                                        height: "24px",
                                        color: "#000",
                                    }}
                                />
                            ) : (
                                <ExpandMoreIcon
                                    style={{
                                        width: "24px",
                                        height: "24px",
                                        color: "#000",
                                    }}
                                />
                            )
                        }
                        onClick={() => setIsOpenMetrics(!isOpenMetrics)}
                    >
                        <Typography
                            sx={{
                                fontSize: "1rem",
                                fontWeight: "500",
                                color: "#3A3F48",
                                textTransform: "none",
                            }}
                        >
                            Metrics
                        </Typography>
                    </Button>
                    <Button
                        sx={{ p: "9px 18px 9px 24px", minWidth: "auto" }}
                        endIcon={
                            isLegend ? (
                                <ExpandLessIcon
                                    style={{
                                        width: "24px",
                                        height: "24px",
                                        color: "#000",
                                    }}
                                />
                            ) : (
                                <ExpandMoreIcon
                                    style={{
                                        width: "24px",
                                        height: "24px",
                                        color: "#000",
                                    }}
                                />
                            )
                        }
                        onClick={() => setIsLegend(!isLegend)}
                    >
                        <Typography
                            sx={{
                                fontSize: "1rem",
                                fontWeight: "500",
                                color: "#3A3F48",
                                textTransform: "none",
                            }}
                        >
                            Legend
                        </Typography>
                    </Button>
                </Box>
            </Box>
            {isOpenMetrics && (
                <Box
                    sx={{
                        display: "flex",
                        gap: ".75rem",
                        flexWrap: "wrap"
                    }}
                >
                    {metrics.length &&
                        metrics.map((metric: IMetric, index: number) => {
                            const style =
                                activeMetrics.findIndex(item => item.key === metric.key) !== -1
                                    ? {
                                        color: "#fff",
                                        background: "var(--button-background-color)",
                                    }
                                    : {
                                        color: "#3A3F48",
                                        background: "inherit",
                                    };
                            return (
                                <Button
                                    key={index}
                                    sx={{
                                        p: "11.5px 24px",
                                        textTransform: "none",
                                        fontSize: "1rem",
                                        fontWeight: "500",
                                        height: "42px",
                                        borderRadius: "0",
                                        ...style,
                                        // backgroundColor: "var(--main-background-color)",
                                        "&:hover": {
                                            ...style,
                                        },
                                    }}
                                    onClick={() => handleClickMetric(metric)}
                                >
                                    {metric.name}
                                </Button>
                            );
                        })}
                </Box>
            )}
            {/* LINE GRAPH   */}
            {activeMetrics.length > 0 && data.length > 0 && (
                <Box
                    sx={{
                        minHeight: "441px",
                        display: "flex",
                        width: "100%",
                    }}
                >
                    <Box
                        sx={{
                            width: groupingLabel ? "85%" : "100%",
                        }}
                    >
                        <ResponsiveContainer width="99%" height="100%">
                            <LineChart
                                data={data}
                                margin={{
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                }}
                            >
                                <CartesianGrid strokeWidth={0.5} opacity={0.4} stroke="grey" vertical={false} />
                                <XAxis dataKey="xAxis" tickCount={20} tickSize={10} />
                                <Tooltip />
                                {isLegend && (
                                    <Legend
                                        iconType="plainline"
                                        wrapperStyle={{
                                            bottom: "-0.5%",
                                        }}
                                    />
                                )}
                                {activeMetrics.length > 0 &&
                                    activeMetrics.map((item, index) =>
                                        activeGroupParams.map(group => (
                                            <>
                                                <YAxis
                                                    orientation={index % 2 === 0 ? "left" : "right"}
                                                    // mirror={(index + 1) % 2 === 0}
                                                    tickMargin={0}
                                                    yAxisId={`${item.key}`}
                                                    tickCount={10}
                                                    tick={{ strokeWidth: 2 }}
                                                    tickSize={3}
                                                    stroke="grey"
                                                    tickLine={false}
                                                    type="number"
                                                    domain={["auto", "auto"]}
                                                    axisLine={false}
                                                    allowDataOverflow
                                                    padding={{ bottom: 10 }}
                                                />
                                                <Line
                                                    dot={false}
                                                    yAxisId={`${item.key}`}
                                                    type="monotone"
                                                    dataKey={`${item.name} | ${group}`}
                                                    stroke={getRandomColor()}
                                                    strokeWidth={2}
                                                    activeDot={{ r: 4 }}
                                                />
                                            </>
                                        ))
                                    )}
                            </LineChart>
                        </ResponsiveContainer>
                    </Box>
                    {groupingLabel && (
                        <Box
                            sx={{
                                p: "10.5px 12px 23px 12px",
                                width: "15%",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: "500",
                                }}
                            >
                                {groupingLabel ? groupingLabel.name : "No group"}s
                            </Typography>
                            <Box
                                sx={{
                                    maxHeight: "380px",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                }}
                            >
                                {groupingParams.length > 0 &&
                                    groupingParams.map((item, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: "flex",
                                                gap: ".5rem",
                                                p: ".5rem 0",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Checkbox checked={activeGroupParams.includes(item)} onChange={() => handleClickGrouping(item)} />
                                            <Typography
                                                sx={{
                                                    fontSize: "1rem",
                                                    fontWeight: "500",
                                                    color: "#3A3F48",
                                                    display: "block",
                                                }}
                                            >
                                                {item || "-"}
                                            </Typography>
                                        </Box>
                                    ))}
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    ) : (
        <Box
            sx={{
                display: "flex",
                gap: ".5rem",
                alignItems: "center",
                p: ".75rem",
                mb: {xs: "1rem", md: "0"},
                opacity: loading ? ".5" : "1",
                pointerEvents: loading ? "none" : "auto",
            }}
        >
            <AutoGraphIcon />
            <Typography
                sx={{
                    fontSize: "1rem",
                    fontWeight: "500",
                }}
            >
                Graph
            </Typography>
            <Box onClick={handleOpenGraph}>
                <AddBadge />
            </Box>
        </Box>
    );
};

export default LineGraphRecharts;
