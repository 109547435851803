import {useNavigate, useParams} from "react-router-dom";
import {FC, useContext, useEffect, useState} from "react";
import {BASE_URL} from "../../../services/constants";
import {getAdStyle, postAdStyle} from "./fetchers";
import AdStyleForm from "../../../components/Forms/AdStyleForm/AdStyleForm";
import {AdStyleType, UserProps} from "./type";
import {AuthContext} from "../../../context/AuthContext";

const AdStyle: FC<UserProps> = ({ mode="create" }) => {
    const {setNotification} = useContext(AuthContext);
    const [user, setUser] = useState<AdStyleType>({} as AdStyleType);
    const navigate = useNavigate();
    const {id} = useParams();

    useEffect(() => {
        if (id) {
            (async () => {
                setUser(await getAdStyle(id));
            })()
        }
    }, [id])

    const getUrl = () => {
        if (mode === "create") {
            return `${BASE_URL}/search/ad_styles/create/`
        }
        return `${BASE_URL}/search/ad_styles/${id}/edit/`
    }

    const onSubmit = async (data: AdStyleType) => {
        const url = getUrl();
        try {
            await postAdStyle(data, url);
            setNotification({
                type: "success",
                open: true,
                message: `Ad style was successfully ${mode === "create" ? "created" : "edited"}`
            })
            navigate("/ad_styles");
        } catch (err) {
            setNotification({
                type: "error",
                open: true,
                message: `Error occurred while ${mode === "create" ? "creating" : "editing"} ad style`
            })
            console.log("Error creating ad style");
        }
    }

    const formData = mode !== "create" && Object.keys(user).length > 0
        ? user as AdStyleType
        : undefined

    return (
        <AdStyleForm mode={mode} formData={formData} onSubmit={onSubmit} />
    )
}

export default AdStyle;