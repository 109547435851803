import CancelIcon from "@mui/icons-material/Cancel";
import {Box} from "@mui/material";
import {FC, ReactNode} from "react";

type PropBadgeProps = {
    id: string;
    children: ReactNode | string;
    filterEntity: Record<string, any>;
    close: (value: Record<string, any>) => void;
};

const PropBadge: FC<PropBadgeProps> = ({ ...props }) => (
    <Box
        id={props.id}
        sx={{
            p: "4px",
            borderRadius: "100px",
            border: "1px solid var(--blue)",
            display: "flex",
            alignItems: "center",
            cursor: "grab",
        }}
    >
        <p style={{ padding: "3px 6px", margin: 0, lineHeight: "16px" }}>{props.children}</p>
        <CancelIcon
            onClick={() => {
                props.close(props.filterEntity);
            }}
            sx={{
                color: "var(--blue)",
                width: "24px",
                height: "24px",
                cursor: "pointer",
            }}
        />
    </Box>
);

export default PropBadge;
