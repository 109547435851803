import {Box, Button, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {Controller} from "react-hook-form";
import {FC} from "react";
import Input from "./Input";

const FormInputs: FC<any> = ({field={}, name, control, formValues = [], error = {}, readOnly=false, isNotView = true}) => {
    const {type, defaultValue, rules, order, title, hint} = field;
    let isBreak = false;
    if (Object.hasOwn(field, "dependencies")) {
        field.dependencies.forEach((dependency: any) => {
            if ((formValues as any)[dependency.fieldKey] && dependency.value !== (formValues as any)[dependency.fieldKey]) {
                isBreak = true;
            }
        })
    }
    if (isBreak) {
        return null;
    }
    if (type === "array") {
        const {fieldsValues: arrFields, append, remove, requiredElementsCount} = field;
        return (
            <Box component="section" sx={{order, display: "flex", flexDirection: "column", width: "100%"}}>
                <Typography sx={{fontWeight: "500", mb: "1.5rem"}}>
                    {title}:
                </Typography>
                {arrFields.map((item: any, index: number) => (
                    <>
                        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", mb: ".5rem"}}>
                            <Typography sx={{fontWeight: "400", color: "#2196F3"}}>Element {index + 1}</Typography>
                            {isNotView &&
                                <IconButton disabled={index < requiredElementsCount} aria-label="delete" size="small" onClick={() => remove(index)}>
                                    <DeleteIcon fontSize="small"/>
                                </IconButton>
                            }
                        </Box>
                        {Object.keys(item).map((key: string) => {
                            if (key === "id") return null;
                            const {title, hint, rules, defaultValue} = field.fieldsSchema[key];
                            return (
                                <>
                                    <Box key={`${name}.${index}.${key}`} sx={{
                                        display: "flex",
                                        alignItems: "self-end",
                                        gap: ".5rem",
                                        flexDirection: "column",
                                        mb: "1.5rem"
                                    }}>
                                        <Controller
                                            key={item.id}
                                            name={`${name}.${index}.${key}` as any}
                                            control={control}
                                            rules={type !== "checkbox" ? rules : {}}
                                            defaultValue={defaultValue}
                                            render={({field: {onChange, value}}) =>
                                                <Input
                                                    readOnly={readOnly}
                                                    errors={error}
                                                    label={title}
                                                    value={value}
                                                    onChange={onChange}
                                                    {...field.fieldsSchema[key]}
                                                    hint={hint}
                                                />
                                            }
                                        />
                                    </Box>
                                </>
                            )
                        })
                        }
                    </>
                ))}
                {!readOnly &&
                    <Button onClick={append} color="primary" size="large" variant="outlined" sx={{borderStyle: "dashed"}}>
                        Add element
                    </Button>
                }
            </Box>
        )
    }
    return (
        <section key={name} style={{width: "100%", height: "fit-content", order}}>
            <Controller
                name={name as any}
                control={control}
                rules={type !== "checkbox" ? rules : {}}
                defaultValue={defaultValue}
                render={({field: inputField}) =>
                    <Input
                        readOnly={readOnly}
                        errors={error}
                        label={title}
                        value={inputField.value}
                        onChange={inputField.onChange}
                        {...field}
                        hint={hint}
                    />
                }
            />
        </section>
    );
}

export default FormInputs;