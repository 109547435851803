import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { createTheme, ThemeProvider } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { DateView, ruRU } from "@mui/x-date-pickers";
import updateLocale from "dayjs/plugin/updateLocale"
import { ReactComponent as DatePickerIcon } from "../../../assets/icons/dateIcon.svg";

dayjs.extend(updateLocale)
dayjs.updateLocale("en", {
    weekStart: 1,
})

const theme = createTheme(
    {
        palette: {
            primary: {
                main: "#538DFE",
            },
            secondary: {
                main: "#11cb5f",
            },
            warning: {
                main: "#538DFE",
            },
        },
    },
    ruRU
);
type Props = {
    views?: DateView[] | null;
    readOnly?: boolean;
    style?: React.CSSProperties;
    label?: string;
    value: Dayjs | string | null | "";
    onChange: (value: Dayjs | null) => void;
    disableBorders?: boolean;
    openTo?: DateView | undefined;
};
const DatePickerCustom = ({ openTo = undefined, views = null, label = "", value = null, onChange, style = {}, readOnly = false, disableBorders = false }: Props) => {
    const date = typeof value === "string" ? dayjs(value) : value;
    const defaultStyles = {
        width: "312px",
        overflow: "initial",
        borderRadius: "0",
        boxShadow: disableBorders ? "0px 0px 12px 0px rgba(0, 0, 0, 0.08)" : "none",
        p: disableBorders ? "6px 12px" : "0",
    };

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                    components={["DatePicker"]}
                    sx={{
                        ...defaultStyles,
                        ...style,
                        width: {xs: "44.2%", md: style.width || defaultStyles.width}
                    }}
                >
                    <DatePicker
                        {...(openTo
                            ? {
                                openTo,
                            }
                            : {})}
                        {...(views
                            ? {
                                views,
                            }
                            : {})}
                        readOnly={readOnly}
                        value={date}
                        sx={{
                            width: "100%",
                            borderRadius: "0",
                            minWidth: "auto !important",
                            ".MuiOutlinedInput-root": {
                                borderRadius: "0",
                            },
                        }}
                        slots={{
                            openPickerIcon: DatePickerIcon,
                        }}
                        format="DD/MM/YYYY"
                        onChange={onChange}
                        {...(disableBorders
                            ? {
                                slotProps: {
                                    textField: {
                                        variant: "standard",
                                        InputProps: {
                                            disableUnderline: true,
                                        },
                                    },
                                },
                            }
                            : { label })}
                        dayOfWeekFormatter={dayOfWeek => {
                            // Возвращаем короткое название дня недели в соответствии с вашим желаемым порядком
                            const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
                            return days[dayOfWeek as any];
                        }}
                    />
                </DemoContainer>
            </LocalizationProvider>
        </ThemeProvider>
    );
};

export default DatePickerCustom;
