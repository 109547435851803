import { Box, Checkbox, FormControlLabel, FormGroup, Menu, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import FilterPanelButton from "../FilterPanelButton";
import { ReactComponent as DensityIcon } from "../../../../assets/icons/density.svg";
import { ReactComponent as WarningIcon } from "../../../../assets/icons/warning.svg";
import Badges from "../TableFilterPanelStatistics/components/Badges";
import {IAggregation, IMetric} from "../../../../pages/buyer/Statistics";
import {BASE_URL} from "../../../../services/constants";
import {Dictionary} from "../../types";

interface IMetrics {
    request: Dictionary<any>;
    setRequest: (value: Dictionary<any>) => void;
    badges?: boolean;
}
const Metrics = ({ request, setRequest, badges = false }: IMetrics) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [metricList, setMetricList] = useState<IAggregation[]>([]);
    const { metrics = {} } = request;

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleSendMetrics = async (data = {}) => {
        try {
            const res = await axios.post(`${BASE_URL}/report/metrics/`, data);
            return await res.data;
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    useEffect(() => {
        (async () => {
            const newMetrics = await handleSendMetrics({
                metrics,
                groups: request.groups,
            });
            setMetricList(newMetrics);
            handleRemoveDisabled(metrics, newMetrics);
        })();
    }, [request.groups]);

    const handleRemoveDisabled = (newMetrics: IMetric[], metricList: IAggregation[]) => {
        const disabledList: string[] = [];
        metricList.forEach(group => {
            group.keys.forEach((option: IMetric) => {
                if (!option.is_enabled) {
                    disabledList.push(option.key);
                }
            });
        });
        // delete all IMetric in metrics array, that are in disabledList
        const updatedMetrics = newMetrics.filter((metric: IMetric) => !disabledList.includes(metric.key));
        if (newMetrics.length !== updatedMetrics.length) {
            setRequest({
                ...request,
                metrics: updatedMetrics,
            });
        }
    };

    const handleChooseParam = async (metric: IMetric) => {
        let newMetrics: IMetric[];
        if (metrics.findIndex((item: IMetric) => item.key === metric.key) !== -1) {
            newMetrics = metrics.filter((item: IMetric) => item.key !== metric.key);
        } else {
            newMetrics = [...metrics, metric];
        }
        setRequest({
            ...request,
            metrics: newMetrics,
        });
        const newMetricList = await handleSendMetrics({
            metrics: newMetrics,
            groups: request.groups,
        });
        setMetricList(newMetricList);
    };

    return (
        <Box
            sx={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                alignItems: "flex-start",
            }}
        >
            <FilterPanelButton Icon={<DensityIcon />} active={Boolean(anchorEl)} onClick={handleMenuOpen} text="Metrics" />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{
                    mt: ".125rem",
                    ".MuiMenu-paper": {
                        p: ".75rem",
                        width: "min-content",
                    },
                    "& .MuiPaper-root": {
                        borderRadius: "0",
                    },
                    ".MuiMenu-list": {
                        p: "0",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                        width: "100%",
                    },
                }}
            >
                <FormGroup
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3, auto)",
                        gap: "0.75rem",
                        boxSizing: "border-box",
                    }}
                >
                    {metricList.length > 0 &&
                        metricList.map((metric, metricIndex) => (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: "600",
                                    }}
                                >
                                    {metric.label}
                                    {metric.keys.length > 0 &&
                                        metric.keys.map((item, index) => (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    width: "100%",
                                                    whiteSpace: "nowrap",
                                                }}
                                            >
                                                <FormControlLabel
                                                    key={index}
                                                    control={
                                                        <Checkbox
                                                            checked={metrics.findIndex((activeMetric: any) => activeMetric.key === item.key) !== -1}
                                                            disabled={!item.is_enabled}
                                                            onChange={() => handleChooseParam(item)}
                                                        />
                                                    }
                                                    label={`${item.name}`}
                                                />
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {!item.is_enabled && (
                                                        <Tooltip title="cant_select_together_warning">
                                                            <WarningIcon />
                                                        </Tooltip>
                                                    )}
                                                    <Tooltip title={item.hint || ""}>
                                                        <HelpOutlineIcon />
                                                    </Tooltip>
                                                </Box>
                                            </Box>
                                        ))}
                                </Typography>
                            </Box>
                        ))}
                </FormGroup>
            </Menu>
            {badges && <Badges filterGroup={metrics} removeBadge={handleChooseParam} keyField="key" request={request} setRequest={setRequest} field="metrics" />}
        </Box>
    );
};

export default Metrics;
