import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Box, Button, Typography} from "@mui/material";
import {DataGrid, GridSingleSelectColDef} from "@mui/x-data-grid";
import {Row} from "../../../components/Table/type";
import {getCategoriesTable} from "./fetchers";
import CustomMenuCell from "../../../components/CustomMenuCell/CustomMenuCell";

const Categories = () => {
    const [rows, setRows] = useState<Row[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [columns, setColumns] = useState<GridSingleSelectColDef[]>([]);
    const navigate= useNavigate();

    const getItems = async () => {
        try {
            setIsLoading(true);
            const data = await getCategoriesTable();
            const cols = data.headers.map((col: any) => ({
                field: col.key,
                headerName: col.label,
                flex: col.key === "id" ? 0 : 1,
                width: col.key === "id" ? 50 : 125
            }))
            cols.push({
                field: "actions",
                headerName: "Actions",
                width: 100,
                sortable: false, // Отключение сортировки для этой колонки
                filterable: false, // Отключение фильтрации для этой колонки
                disableColumnMenu: true, // Отключение меню колонки
                renderCell: (cellParams: any) => <CustomMenuCell cellParams={cellParams} />,
            })
            setRows(data.rows);
            setColumns(cols);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getItems();
    }, [])

    const goToCreateRelatedSearch = () => navigate("create");

    return (
        <>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", mb: {xs: "0", md: "1.5rem"}, py: "1rem"}}>
                <Typography sx={{fontWeight: "500", fontSize: {xs: "1.25rem", md: "2.125rem"}}} component="h1">
                    Categories management
                </Typography>
                <Button sx={{minWidth: "48px", fontSize: {xs: ".75rem", md: ".875rem"}}} variant="contained" color="info" onClick={goToCreateRelatedSearch}>
                    + Create
                </Button>
            </Box>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                autoHeight
                loading={isLoading}
                hideFooterSelectedRowCount
            />
        </>
    )
}

export default Categories;