import {
    Box, Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel, MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField, Typography
} from "@mui/material";
import {FieldError} from "react-hook-form";
import {FC} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import IconButton from "@mui/material/IconButton";
import DragDropElements from "../../DragNDrop/DraggableContainer";

type OptionObjectType = {
    key: string,
    value: string
}

type InputProps = {
    label: string,
    hint: string,
    value: string | number | File,
    type: string,
    errors: FieldError,
    onChange: (e: any) => void,
    readOnly: boolean,
    options: Array<string | OptionObjectType>,
    rules: {
        [key: string]: {
            value: boolean,
            message: string
        }
    }

}
const Input: FC<InputProps> = ({ label, value, hint, onChange, type, errors, ...rest }) => {
    const isError = Object.keys(errors).length > 0;
    switch (type) {
        case "string":
            return (
                <TextField
                    sx={{width: "100%", "&.hover": {color: "blue"}}}
                    InputProps={{
                        readOnly: rest.readOnly,
                    }}
                    disabled={false}
                    error={isError}
                    id="standard-error-helper-text"
                    label={label}
                    helperText={errors?.message}
                    variant="standard"
                    value={value}
                    onChange={onChange}
                />
            );
        case "textarea":
            return (
                <TextField
                    InputProps={{
                        readOnly: rest.readOnly,
                    }}
                    error={isError}
                    helperText={errors?.message}
                    id="outlined-multiline-static"
                    label={label}
                    sx={{ width: "100%", borderRadius: "0 !important", borderColor: "var(--blue) !important" }}
                    multiline
                    rows={8}
                    defaultValue={value}
                    value={value}
                    onChange={onChange}
                />
            );
        case "number":
            return (
                <TextField
                    sx={{width: "100%"}}
                    type="number"
                    InputProps={{
                        readOnly: rest.readOnly,
                    }}
                    disabled={false}
                    error={isError}
                    id="standard-error-helper-text"
                    label={label}
                    helperText={errors?.message}
                    variant="standard"
                    value={value}
                    onChange={onChange}
                />
            );
        case "checkbox":
            return (
                <FormControl sx={{ width: "100%", order: 10, ml: "4px" }} required={rest.rules?.required?.value}>
                    <FormLabel id="demo-row-radio-buttons-group-label" sx={{ fontWeight: "500", color: "#2196F3" }}>
                        {label}
                    </FormLabel>
                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" value={value.toString()}
                        onChange={(event, value) => onChange(value === "true")}
                    >
                        <FormControlLabel disabled={rest.readOnly} value control={<Radio color="info" />} label="Yes" />
                        <FormControlLabel disabled={rest.readOnly} value={false} control={<Radio color="info" />} label="No" />
                    </RadioGroup>
                </FormControl>
            );
        case "multiple-checkboxes":
            return (
                <>
                    <Typography>{label}</Typography>
                    { rest.options && rest.options.length > 0 ? rest.options.map((option: string | OptionObjectType, index: number) => {
                        const isChecked = (value as string).includes(option as string);
                        return (
                            <FormControlLabel key={index} control={
                                <Checkbox
                                    checked={isChecked}
                                    onChange={e => {
                                        if ((value as string).includes(option as string)) {
                                            onChange((value as string).split(",").filter(item => item !== option).join(","));
                                        } else {
                                            const newValue = (value as string).split(",");
                                            newValue.push(option as string);
                                            onChange(newValue.join(","));
                                        }
                                    }}
                                />
                            } label={option as string} />
                        )
                    }) : <Typography sx={{py: ".5rem", fontSize: "1.25rem", fontWeight: 500}}>No data</Typography>}
                </>
            )
        case "select":
            return (
                <FormControl
                    error={isError}
                    sx={{ m: 0, minWidth: 120, width: "100%", order: 9}} required={rest.rules?.required?.value}
                >
                    <InputLabel id="demo-select-small-label">{label}</InputLabel>
                    <Select
                        disabled={false}
                        readOnly={rest.readOnly}
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={value}
                        label={label}
                        sx={{height: "56px"}}
                        onChange={onChange}
                    >
                        {rest.options.map((item: string | OptionObjectType, index: number) => {
                            let value = typeof item === "object" ? item.value : item;
                            const key = typeof item === "object" ? item.key : item;
                            if (!value) {
                                value = key;
                            }
                            return (
                                <MenuItem key={index} value={key}>{value}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            );
        case "file":
            const fileName = value && value instanceof File ? (value as File).name : (value || "");
            return (
                <>
                    <Typography sx={{fontWeight: "500", fontSize: "1rem", mb: "1rem", order: 20}}>
                        {label}
                    </Typography>
                    { !fileName
                        ? (
                            <>
                                <label htmlFor={label} className="input-file" style={{borderColor: isError ? "red": "", order: 21}}>
                                    <input
                                        style={{width: "100%", height: "42px"}}
                                        id={label} type="file" name="file"
                                        onChange={event => {
                                            if (event?.target?.files) {
                                                onChange(event?.target?.files[0]);
                                            }
                                        }}
                                    />
                                    <span className="input-file-btn" style={{color: isError ? "red": ""}}>Choose a file</span>
                                    {/* <span className="input-file-text">{fileName}</span> */}
                                </label>
                                <span style={{color: "#d32f2f", fontSize: ".75rem"}}>{errors?.message}</span>
                            </>
                        ) : (
                            <Box sx={{width: "100%", display: "flex", gap: "1rem", alignItems: "center", overflow: "hidden"}}>
                                <UploadFileIcon color="info"/>
                                <Box sx={{display: "flex", width: "90%", justifyContent: "space-between", alignItems: "center"}}>
                                    <Typography sx={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "-webkit-box",
                                        whiteSpace: "nowrap",
                                        WebkitLineClamp: "1",
                                        WebkitBoxOrient: "vertical",
                                        width: "100%"
                                    }}>
                                        {(fileName as string).includes("media/croxy/")
                                            ? (fileName as string).split("/").slice(4).join("/")
                                            : fileName
                                        }
                                    </Typography>
                                    <IconButton onClick={() => onChange(null)}>
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            </Box>
                        )
                    }
                </>
            )
        case "drag-drop":
            return (
                <>
                    <Typography sx={{fontWeight: "500", fontSize: "1rem", mb: "1.5rem"}}>{label}</Typography>
                    <DragDropElements itemsOrder={value as string} onChange={onChange}/>
                </>
            )
        default:
            return null;
    }
};


export default Input;