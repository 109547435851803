import {Box, Button, Typography} from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {FC} from "react";
import FormInputs from "./formInputs";

type FormType = {
    title: string,
    formValues: any,
    goBackHandler: () => void,
    submit: () => void,
    isNotView: boolean,
    loading: boolean,
    mode: string,
    fields: any,
    control: any,
    readOnly: boolean,
    errors: any
}

const Form: FC<FormType> = ({ title="Form", formValues, control, goBackHandler, submit, isNotView = false, loading=false, mode="create", fields, errors, readOnly}) => {
    const leftFields = Object.keys(Object.fromEntries(Object.entries(fields).filter(item => (item as any)[1].float === "left")));
    const rightFields = Object.keys(Object.fromEntries(Object.entries(fields).filter(item => (item as any)[1].float === "right")));
    return (
        <>
            <Box sx={{display: "flex", width: "100%", justifyContent: "space-between", mb: "1.5rem", alignItems: "center"}}>
                <Typography component="h2" sx={{fontSize: {xs: "1rem", md: "1.5rem"}, fontWeight: "500", order: 0}}>
                    {title}
                </Typography>
                <Box sx={{display: "flex", gap: ".5rem", alignItems: "center"}}>
                    <Button onClick={goBackHandler} sx={{color: "gray"}} startIcon={<ArrowBackIcon />}>
                        Back
                    </Button>
                    {isNotView &&
                        <Button type="button" onClick={submit} disabled={loading} variant="contained" color="success" sx={{order: 33, width: "50%", alignSelf: "center"}}>
                            {mode === "create" ? "Create" : "Edit"}
                        </Button>
                    }
                </Box>
            </Box>
            <form onSubmit={submit} style={{display: "flex", paddingBottom: "1rem"}}>
                <Box sx={{display: "flex", flexDirection: {xs: "column", md: "row"}, flexWrap: "wrap", gap: "1.5rem", width: "100%"}}>
                    <Box sx={{width: {xs: "100%", md: "calc(50% - 0.75rem)"}, float: "left", display: "flex", gap: "1.5rem", flexDirection: "column"}}>
                        { leftFields.map((name: string, index) => (
                            <FormInputs
                                key={index}
                                field={fields[name]}
                                name={name}
                                control={control}
                                readOnly={readOnly}
                                isNotView={isNotView}
                                formValues={formValues}
                                error={errors[name] || {}}
                            />
                        ))}
                    </Box>
                    <Box sx={{width: {xs: "100%", md: "calc(50% - 0.75rem)"}, float: "right", display: "flex", gap: "1.5rem", flexDirection: "column"}}>
                        { rightFields.map((name: string, index) => (
                            <FormInputs
                                key={index}
                                field={fields[name]}
                                name={name}
                                control={control}
                                readOnly={readOnly}
                                isNotView={isNotView}
                                formValues={formValues}
                                error={errors[name] || {}}
                            />
                        ))}
                    </Box>
                </Box>
            </form>
        </>
    )
}

export default Form;