import axios from "axios";
import {BASE_URL} from "../../../services/constants";

export const getAdStylesTable = async () => {
    try {
        const res = await axios.get("/search/ad_styles/list/");
        return await res.data;
    } catch (err) {
        throw new Error("Error getRelatedSearchesTable");
    }
}

export const getAdStyle = async (id: string) => {
    try {
        const res = await axios.get(`${BASE_URL}/search/ad_styles/${id}/details/`);
        return res.data;
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
}

export const postAdStyle = async (data: any, url: string) => {
    try {
        const res = await axios.post(url, data);
        return res.data;
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
}