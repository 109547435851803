import React, { ReactElement } from "react";
import { Button } from "@mui/material";

interface CSSPropertiesForMUI extends React.CSSProperties {
    ":hover"?: Record<string, any>;
}

type Props = {
    label: string;
    Icon?: ReactElement | null;
    leftIcon?: ReactElement | null;
    onChange: (value: any) => void;
    style?: CSSPropertiesForMUI | any;
    mode?: string;
    disabled?: boolean;
};
type Modes = {
    [key: string]: Object;
};
const CustomButton = ({ leftIcon = null, label = "", Icon = null, onChange, style = {}, mode = "warning", disabled = false }: Props) => {
    const modes: Modes = {
        warning: {
            background: "var(--blue)",
            color: "#fff",
            "&:hover": {
                color: "#fff",
                opacity: "0.8",
                background: "var(--button-background-color)",
            },
        },
        dark: {
            background: "inherit",
            color: "var(--main-black-color)",
            "&:hover": {
                color: "var(--main-black-color)",
            },
        },
        light: {
            background: "inherit",
            color: "#fff",
            "&:hover": {
                color: "#fff",
                background: "#00000033",
            },
        },
    };
    return (
        <Button
            onClick={onChange}
            sx={{
                display: "flex",
                p: "8px 22px 8px 19px",
                height: "42px",
                borderRadius: ".25rem",
                fontSize: "1rem",
                fontWeight: "500",
                gap: "0",
                ...modes[mode],
                ...style,
            }}
            startIcon={leftIcon}
            endIcon={Icon}
            disabled={disabled}
        >
            {label}
        </Button>
    );
};

export default CustomButton;
