import { FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, Tooltip } from "@mui/material";
import React, { ReactElement } from "react";
import { DeepRequired, FieldErrorsImpl, FieldValues, GlobalError } from "react-hook-form";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

interface Props {
    errors?: (Partial<FieldErrorsImpl<DeepRequired<FieldValues>>> & { root?: Record<string, GlobalError> & GlobalError }) | Record<string, any>;
    multiline?: boolean;
    autoComplete?: boolean;
    type?: string;
    label: string;
    value: string | number | null | undefined;
    Icon?: ReactElement | null;
    style?: React.CSSProperties;
    readOnly?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    border?: boolean;
    hint?: string;
}
const ValueInputField = ({
    errors = {},
    border = true,
    multiline = false,
    autoComplete = false,
    type = "text",
    Icon = null,
    label = "",
    value = "",
    style = { width: "312px" },
    readOnly = false,
    onChange = () => {},
    hint = "",
}: Props) => {

    const defaultStyle = {
        width: "312px",
    };
    let labelTop = "0px";
    let inputHeight = "auto";
    if (style.top) {
        labelTop = `${style.top}px`;
    }
    if (style.height) {
        inputHeight = `${style.height}px`;
    }
    delete style?.height;
    delete style.top;
    const isError = Object.keys(errors).length > 0;

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        e.stopPropagation();
    };

    return (
        <FormControl
            sx={{
                ...defaultStyle,
                ...style,
            }}
            variant="outlined"
            error={isError}
        >
            <InputLabel htmlFor={`outlined-adornment-${label}`} sx={{ top: labelTop }}>
                {label}
            </InputLabel>
            <OutlinedInput
                onKeyDown={onKeyDown}
                error={Object.keys(errors).length > 0}
                multiline={multiline}
                rows={3}
                autoComplete={autoComplete ? "new-password" : "off"}
                readOnly={readOnly}
                sx={{
                    borderRadius: "0",
                    height: inputHeight,
                    ...(isError
                        ? {
                            ".MuiOutlinedInput-notchedOutline": {
                                borderWidth: border ? "1px" : "0px",
                            },
                            ".Mui-focused": {
                                borderWidth: "1px",
                            },
                        }
                        : {}),
                }}
                id={`outlined-adornment-${label}`}
                type={type}
                endAdornment={
                    <>
                        {hint && (
                            <Tooltip title={hint} style={{ color: "gray" }}>
                                <HelpOutlineIcon />
                            </Tooltip>
                        )}
                        {Icon && <InputAdornment position="end">{Icon}</InputAdornment>}
                    </>
                }
                label={label}
                color="warning"
                value={value?.toString()}
                onChange={onChange}
            />
            {isError && (
                <FormHelperText
                    sx={{
                        width: "100%",
                        m: "0",
                        background: "#fff",
                    }}
                    id={`outlined-adornment-${label}`}
                >
                    {errors.message || ""}
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default ValueInputField;
