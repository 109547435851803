import React, {createContext, Dispatch, FC, ReactNode, SetStateAction, useEffect, useState} from "react";
import {getMe} from "../services/fetchers";
import Loading from "../components/Loading/Loading";

interface AuthContextInterface {
    me: Record<string, any>,
    loading: boolean,
    isLoggedIn: boolean,
    setIsLoggedIn: (value: boolean) => void,
    setMe: (value: Record<string, any>) => void,
    notification: Record<any, any>,
    setNotification: Dispatch<SetStateAction<{}>>,
    handleCloseNotification: (event?: React.SyntheticEvent | Event, reason?: string) => void
}

export const AuthContext = createContext<AuthContextInterface>({} as AuthContextInterface);

type AuthProviderType = {
    children: ReactNode
}

const AuthProvider: FC<AuthProviderType> = ({children}) => {
    const [me, setMe] = useState<Record<string, any>>({});
    const [notification, setNotification] = useState({});
    const [loading, setLoading] = useState<boolean>(true);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    const handleCloseNotification = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        setNotification({
            open: false
        });
    };

    useEffect(() => {
        if (Object.keys(me).length === 0) {
            (async () => {
                try {
                    setMe(await getMe());
                    setIsLoggedIn(true);
                } catch (err) {
                    setIsLoggedIn(false);
                    console.error("You are not authorized.");
                } finally {
                    setLoading(false);
                }
            })()
        }
    }, [isLoggedIn])

    if (loading) {
        return <Loading />
    }

    return (
        <AuthContext.Provider value={{me, setMe, loading, isLoggedIn, setIsLoggedIn, notification, setNotification, handleCloseNotification}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;