import { Box, Menu } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CheckIcon from "@mui/icons-material/Check";
import {Dictionary, Header} from "../types";
import SelectInputField from "../../../UI/Inputs/SelectInputField";
import AddBadge from "../../Badges/AddBadge";
import RemoveBadge from "../../Badges/RemoveBadge";
import ValueInputField from "../../../UI/Inputs/ValueInputField";
import CustomButton from "../../../UI/Buttons/CustomButton";
import FilterPanelButton from "./FilterPanelButton";
import { ReactComponent as FilterIcon } from "../../../assets/icons/filter.svg";
import { ReactComponent as ResetIcon } from "../../../assets/icons/reset.svg";
import PropBadge from "../../Badges/PropBadge";
import {generateId} from "../../../services/helpers";

interface Filter {
    id: string;
    label: string;
    value: string;
}

interface ColumnFilterProps {
    headers: Header[];
    request: Dictionary<any>;
    setRequest: (value: Dictionary<any>) => void;
    badges?: boolean;
}
const ColumnFilter: React.FC<ColumnFilterProps> = ({ request, setRequest, headers, badges = false }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [headerItems, setHeaderItems] = useState<string[]>([]);
    const [filters, setFilters] = useState<Filter[]>([
        {
            id: generateId(),
            label: "",
            value: "",
        },
    ]);

    useEffect(() => {
        const visibleHeaderLabels: string[] = [];
        headers.forEach(header => {
            if (header.visible) {
                visibleHeaderLabels.push(header.label);
            }
        });
        setHeaderItems(visibleHeaderLabels);
    }, [headers]);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleAddFilter = () => {
        setFilters([
            ...filters,
            {
                id: generateId(),
                label: "",
                value: "",
            },
        ]);
    };
    const handleResetFilters = () => {
        setFilters([
            {
                id: generateId(),
                label: "",
                value: "",
            },
        ]);
    };
    const handleRemoveFilter = (id: string) => {
        const newFilters = filters.filter(el => {
            if (el.id !== id) {
                return el;
            }
            let newValue = [];
            if (el.label) {
                newValue = [...headerItems, el.label];
            } else {
                newValue = [...headerItems];
            }
            setHeaderItems(newValue);
            return null;
        });
        if (newFilters.length === 0) {
            newFilters.push({
                id: generateId(),
                label: "",
                value: "",
            });
        }
        setFilters(newFilters);
    };
    const handleChangeFilter = (id: string, label: string, value: string) => {
        const newFilters = filters.map(el => {
            if (el.id === id) {
                if (value === "") {
                    let newValue = [];
                    if (el.label) {
                        newValue = [...headerItems.filter(col => col !== label), el.label];
                    } else {
                        newValue = [...headerItems.filter(col => col !== label)];
                    }
                    setHeaderItems(newValue);
                }
                return {
                    ...el,
                    label,
                    value,
                };
            }
            return el;
        });
        setFilters(newFilters);
    };

    const handleApplyFilters = useCallback(() => {
        const newFilters: { [key: string]: any } = {};
        filters.forEach(filter => {
            const key = headers.find(header => header.label === filter.label)?.key || "";
            if (filter.value) {
                newFilters[key] = filter.value;
            } else {
                filter.label = "";
            }
        });
        setRequest({
            ...request,
            filters: newFilters as any,
        });
    }, [filters]);

    return (
        <Box
            sx={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                alignItems: "flex-start",
            }}
        >
            <FilterPanelButton active={Boolean(anchorEl)} Icon={<FilterIcon />} text="Filters" onClick={handleMenuOpen} />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{
                    mt: ".125rem",
                    "& .MuiPaper-root": {
                        borderRadius: "0",
                        p: "1.5rem",
                    },
                    ".MuiMenu-list": {
                        p: "0",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                    },
                }}
            >
                {filters.length > 0 &&
                    filters.map((filter: Filter, index: number) => (
                        <Box
                            key={index}
                            sx={{
                                display: "flex",
                                width: "100%",
                                gap: "1rem",
                            }}
                        >
                            <SelectInputField
                                style={{ width: "19.5rem" }}
                                value={filter.label}
                                label="Columns"
                                items={filter.label ? [...headerItems, filter.label] : headerItems}
                                onChange={value => handleChangeFilter(filter.id, value, "")}
                            />
                            <ValueInputField
                                style={{ width: "19.5rem" }}
                                label="filter"
                                value={filter.value}
                                Icon={<SearchIcon />}
                                onChange={e => handleChangeFilter(filter.id, filter.label, e.target.value)}
                            />
                            {index === 0 ? (
                                <Box
                                    onClick={handleAddFilter}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                    }}
                                >
                                    <AddBadge />
                                </Box>
                            ) : (
                                <Box
                                    onClick={() => handleRemoveFilter(filter.id)}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                    }}
                                >
                                    <RemoveBadge />
                                </Box>
                            )}
                        </Box>
                    ))}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <CustomButton Icon={<CheckIcon />} label="button.apply" onChange={handleApplyFilters} />
                    <CustomButton Icon={<ResetIcon />} mode="dark" label="button.reset" onChange={handleResetFilters} />
                </Box>
            </Menu>
            {badges && (
                <Box
                    sx={{
                        display: "flex",
                        ml: "12px",
                        alignItems: "center",
                        gap: "12px",
                        flexWrap: "wrap",
                        p: "9px 0",
                    }}
                >
                    {filters.map(
                        filter =>
                            filter.value && (
                                <PropBadge id={filter.id} filterEntity={filter} close={field => handleRemoveFilter(field.id)}>
                                    {`${filter.label}=${filter.value}`}
                                </PropBadge>
                            )
                    )}
                </Box>
            )}
        </Box>
    );
};

export default ColumnFilter;
