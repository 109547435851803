import React, {useContext, useEffect, useState} from "react";
import dayjs from "dayjs";
import axios from "axios";
import {Link, useSearchParams} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import {DataGrid, GridSingleSelectColDef, useGridApiRef} from "@mui/x-data-grid";
import {generateId} from "../../../services/helpers";
import {AuthContext} from "../../../context/AuthContext";
import {Row} from "../../../components/TableConstructor/types";
import {BASE_URL} from "../../../services/constants";
import NothingFound from "../../../components/NothingFound";
import Loading from "../../../components/Loading/Loading";
import Period from "../../../components/TableConstructor/components/Period";
import LineGraphDashboard from "./LineGraphDashboard";

export interface IMetric {
    key: string;
    name: string;
    is_enabled: boolean;
    hint?: string;
}
export type IRequests = Record<string, any>;

const initialRequests = {
    periods: {
        date_from: dayjs().subtract(7, "day"),
        date_to: dayjs().add(-1, "day"),
        currency: "",
        periodHotKey: null
    },
    filters: [
        {
            fieldId: generateId(),
            key: "",
            option: "",
            value: "",
        },
    ],
    metrics: [],
    groups: [],
};

const DashboardPage = () => {
    const apiRef = useGridApiRef();
    const { setNotification } = useContext(AuthContext);
    const [requests, setRequests] = useState<IRequests>(initialRequests);
    const [headers, setHeaders] = useState<GridSingleSelectColDef[]>([]);
    const [rows, setRows] = useState<Row[] | null>(null);
    const [groupingLabel, setGroupingLabel] = useState<IMetric>({} as IMetric);
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [total, setTotal] = useState<Record<string, number>>({});

    useEffect(() => {
        const requestsJson = searchParams.get("requests");
        if (requestsJson) {
            const decodedRequests = JSON.parse(requestsJson);
            setRequests(decodedRequests);
        }
    }, []);

    const handleUpdate = async (signal: any = null) => {
        setLoading(true);
        const data = {
            ...requests,
            periods: {
                ...requests.periods,
                date_from: dayjs(requests.periods.date_from).format("YYYY-MM-DD"),
                date_to: dayjs(requests.periods.date_to).format("YYYY-MM-DD"),
            },
        };
        try {
            const res = await axios.post(`${BASE_URL}/report/main/`, data);
            const table = await res.data;
            const cols = table.headers.map((col: any) => ({
                field: col.key,
                headerName: col.label,
                type: col.type || "string",
                width: col.key === "id"
                    ? 50
                    : col.key === "date"
                        ? 100
                        : col.key === "channel__name_custom"
                            ? 100
                            : col.key === "cost_fb_sum"
                                ? 85
                                : col.key === "campaign_prefix"
                                    ? 120
                                    : 85,
                sortComparator: (value1: any, value2: any, params1: any, params2: any) => {
                    if (params1.id === "-" || params2.id === "-") {
                        return 0;
                    }
                    if (col.type === "string") {
                        return value1.localeCompare(value2);
                    }
                    return value1 - value2;
                },
                renderCell: (params: any) => {
                    if (params.colDef.field === "channel__name_custom") {
                        return <Link to={`/rs?channel=${params.formattedValue}`} target="_blank" style={{fontWeight: "600", textDecoration: "underline !important", color: "var(--main-purple-color)"}}>
                            {params.formattedValue}
                        </Link>
                    }
                    if (params.colDef.type === "number") {
                        return params.formattedValue.toLocaleString("ru-RU", {
                            maximumFractionDigits: 3,
                        });
                    }
                    return params.formattedValue;
                }
            }))
            const rows = [{
                id: "-", ...table.total,
            }, ...table.rows];
            setRows(rows);
            setHeaders(cols);
            setGroupingLabel(table.group);
            if (requests.metrics.length === 0) {
                setRequests({
                    ...requests,
                    metrics: table.metrics,
                    groups: table.groups
                })
            }
            setTotal(table.total);
        } catch (err: any) {
            setNotification({
                open: true,
                message: err.response.data.message,
                type: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        if (requests.periods) {
            (async () => handleUpdate(controller.signal))();
        }
        return () => controller.abort();
    }, [requests.periods]);

    return (
        <>
            <Box sx={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", mb: "1.5rem"}}>
                <Typography sx={{fontWeight: "500", fontSize: {xs: "1.25rem", md: "1.5rem"}}} component="h1">
                    Dashboard
                </Typography>
            </Box>
            <Box sx={{p: ".5rem", mb: "1rem", borderRadius: ".25rem", border: "1px solid rgba(0, 0, 0, 0.12)"}}>
                <Period setRequest={setRequests} request={requests} isStatistics={false} />
            </Box>
            {rows &&
                (headers.length > 0 && rows.length > 0 ? (
                    <>
                        <LineGraphDashboard loading={loading} requests={requests} rows={rows.slice(1)} headers={headers} groupingLabel={groupingLabel} />
                        <DataGrid
                            apiRef={apiRef}
                            rows={rows}
                            columns={headers}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 15 },
                                },
                            }}
                            pageSizeOptions={[15, 25, 50, 100]}
                            autoHeight
                            loading={loading}
                            hideFooterSelectedRowCount
                            getRowClassName={params => params.id === "-" ? "bold-row" : ""}
                        />
                    </>
                ) : (
                    <NothingFound />
                ))}
            {loading && !rows && (
                <Box
                    sx={{
                        height: "300px",
                        display: "flex",
                    }}
                >
                    <Loading />
                </Box>
            )}
        </>
    );
};

export default DashboardPage;
