import {FC, ReactElement, useContext} from "react";
import {Navigate, Outlet} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";

type ProtectedRouteType = {
    permission: string,
    redirectPath?: string,
    children?: ReactElement
}

const ProtectedRoute: FC<ProtectedRouteType> = ({
    permission,
    redirectPath = "/rs",
    children=undefined,
}) => {
    const {me} = useContext(AuthContext);
    if (!me.permissions.includes(permission)) {
        return <Navigate to={redirectPath} replace />;
    }

    return children || <Outlet />;
};

export default ProtectedRoute;