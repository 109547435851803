import {Dayjs} from "dayjs";

export const getFormData = (object: any) => {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
}

export const generateId = () => `id${Math.random().toString(16).slice(2)}`;

export const addToClipBoard = (value: string) => {
    if (navigator) {
        navigator.clipboard.writeText(value);
    }
};

export const handleChangeInnerField = (key: string, fieldKey: string, value: string | number | boolean | Dayjs | null, setState: (state: any) => void) => {
    setState((prevValue: any) => ({
        ...prevValue,
        [key]: {
            ...(typeof prevValue[key] === "object" ? prevValue[key] : {}),
            [fieldKey]: value,
        },
    }));
};

export const handleChangeField = (key: any, value: any, state: any, setState: (item: any) => void) => {
    setState({
        ...state,
        [key]: value,
    });
};