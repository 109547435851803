import {FC, useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import "../Form/input-file.scss";
import formFields from "./formFields";
import Form from "../Form/Form";
import {AdStyleFormProps} from "./type";
import {CategoryType} from "../../../pages/buyer/Categories/type";

const CategoryForm: FC<AdStyleFormProps> = ({mode="create", formData={}, onSubmit=() => {}}) => {
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        formState: { errors },
    } = useForm<CategoryType>();

    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const isNotView = mode !== "view";
    const readOnly = !isNotView || loading;
    const formValues = getValues();

    useEffect(() => {
        if (Object.keys(formData).length > 0) {
            reset({
                ...formData,
            });
        }
    }, [formData])

    const submitForm = async (data: any) => {
        if (isNotView) {
            try {
                setLoading(true);
                await onSubmit(data);
            } catch (error) {
                console.error("Error while submitting form!");
            } finally {
                setLoading(false);
            }
        }
    }

    const fields = useMemo(() => ({
        ...formFields,
    }), [])

    const goBackHandler = () => navigate(-1);
    const formTitle = mode === "create"
        ? "Create new Category"
        : mode === "edit"
            ? "Edit Category"
            : "View Category"

    return (
        <Form
            formValues={formValues}
            title={formTitle}
            control={control}
            errors={errors}
            fields={fields}
            goBackHandler={goBackHandler}
            isNotView={isNotView}
            loading={loading}
            mode={mode}
            readOnly={readOnly}
            submit={handleSubmit(submitForm)}
        />
    )
}

export default CategoryForm;
