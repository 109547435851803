export default {
    channel_name: {
        title: "Custom name for channel",
        defaultValue: "",
        hint: "Custom name for channel",
        order: 1,
        rules: {
            required: {
                value: true,
                message: "Custom name is required"
            }
        },
        type: "string",
        typeSend: "string",
        float: "left",
    },
    channel_type: {
        title: "Channel type",
        defaultValue: "Croxy",
        hint: "Channel type",
        order: 2,
        rules: {
            required: {
                value: true,
                message: "Channel type is required"
            }
        },
        options: ["Croxy", "Reports"],
        type: "select",
        typeSend: "string",
        float: "left",
    },
    channel: {
        title: "Channel ID from Adsense",
        defaultValue: "",
        hint: "Write channel ID",
        order: 3,
        rules: {
            required: {
                value: true,
                message: "Channel ID is required"
            }
        },
        type: "string",
        typeSend: "string",
        float: "left",
    },
    username: {
        title: "Choose username for this channel",
        defaultValue: "",
        hint: "Choose username for this channel",
        order: 4,
        type: "multiple-checkboxes",
        typeSend: "string",
        float: "left",
    },
}
