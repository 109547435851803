import { Box, Typography } from "@mui/material";
import React from "react";

const NothingFound = () => (
    <Box
        sx={{
            display: "flex",
            justifyContent: "center",
            mt: ".75rem",
            alignItems: "center",
            p: ".75rem 0",
            flexDirection: "column",
            gap: ".75rem",
        }}
    >
        <Typography
            sx={{
                color: "var(--main-purple-color)",
                fontSize: "1.5rem",
                fontWeight: "500",
            }}
        >
            Nothing Found
        </Typography>
        <Typography sx={{ color: "var(--main-grey-color)" }}>Nothing was found matching your parameters.</Typography>
    </Box>
);

export default NothingFound;
