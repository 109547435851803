export default {
    first_name: {
        title: "First Name",
        defaultValue: "",
        hint: "Write first name of user",
        order: 1,
        rules: {
            required: {
                value: true,
                message: "First name is required"
            }
        },
        type: "string",
        typeSend: "string",
        float: "left",
    },
    last_name: {
        title: "Last Name",
        defaultValue: "",
        hint: "Write last name of user",
        order: 2,
        rules: {
            required: {
                value: true,
                message: "Last name is required"
            }
        },
        type: "string",
        typeSend: "string",
        float: "left",
    },
    username: {
        title: "Username",
        defaultValue: "",
        hint: "Write username",
        order: 3,
        rules: {
            required: {
                value: true,
                message: "Username is required"
            }
        },
        type: "string",
        typeSend: "string",
        float: "left",
    },
    channels: {
        title: "Active Channels",
        defaultValue: "",
        hint: "Choose channels for user",
        order: 4,
        type: "multiple-checkboxes",
        typeSend: "string",
        float: "left",
    },
    ad_styles: {
        title: "Active styles",
        defaultValue: "",
        hint: "Choose styles for user",
        order: 5,
        type: "multiple-checkboxes",
        typeSend: "string",
        float: "left",
    },
    password: {
        title: "Password",
        defaultValue: "",
        hint: "Change password",
        order: 6,
        type: "string",
        typeSend: "string",
        float: "left",
    },
    is_active: {
        title: "Does user active?",
        defaultValue: false,
        hint: "Enable or disable user",
        order: 5,
        rules: {
            required: {
                value: true,
                message: "Field is required"
            }
        },
        type: "checkbox",
        typeSend: "boolean",
        float: "right",
    },
    user_view: {
        title: "Does user see Users section?",
        defaultValue: false,
        hint: "Enable or disable Users section for this user",
        order: 6,
        rules: {
            required: {
                value: true,
                message: "Field is required"
            }
        },
        type: "checkbox",
        typeSend: "boolean",
        float: "right",
    },
    channel_view: {
        title: "Does user see Channels section?",
        defaultValue: false,
        hint: "Enable or disable Channels section for this user",
        order: 7,
        rules: {
            required: {
                value: true,
                message: "Field is required"
            }
        },
        type: "checkbox",
        typeSend: "boolean",
        float: "right",
    },
    style_view: {
        title: "Does user see Styles section?",
        defaultValue: false,
        hint: "Enable or disable Styles section for this user",
        order: 8,
        rules: {
            required: {
                value: true,
                message: "Field is required"
            }
        },
        type: "checkbox",
        typeSend: "boolean",
        float: "right",
    },
    category_view: {
        title: "Does user see Categories section?",
        defaultValue: false,
        hint: "Enable or disable Categories section for this user",
        order: 9,
        rules: {
            required: {
                value: true,
                message: "Field is required"
            }
        },
        type: "checkbox",
        typeSend: "boolean",
        float: "right",
    },
}
