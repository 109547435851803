import React from "react";
import CustomButton from "../CustomButton";
import { ReactComponent as DraftIconBlack } from "../../../assets/icons/draftIconBlack.svg";

interface DraftButtonProps {
    action: () => void;
}

const DraftButton: React.FC<DraftButtonProps> = ({ action }) => <CustomButton mode="dark" style={{width: {xs: "80%", md: "auto"}, color: "var(--blue)"}} label="Draft" onChange={action} />;
export default DraftButton;
