import {Box, Divider} from "@mui/material";
import React, { FC, ReactElement } from "react";
import Period from "../Period";
import ReportFilter from "../ReportFilter";
import Metrics from "../Metrics";
import Grouping from "../Grouping";
import { thickRow } from "../../constants";
import DensePadding from "../DensePadding";
import ColumnFilter from "../ColumnFilter";
import ColumnSelection from "../ColumnSelection";
import { Header, Row } from "../../types";
import DatePickerCustom from "../../../../UI/Inputs/DatePicker";
import { ReactComponent as PeriodIcon } from "../../../../assets/icons/periodIcon.svg";
import {handleChangeField} from "../../../../services/helpers";

type TableFilterPanelStatisticsProps = {
    rowDense?: number;
    setRowDense?: (value: number) => void;
    loading?: boolean;
    rows?: Row[];
    tableActions?: Record<string, any>;
    request?: Record<string, any>;
    setRequest?: (value: Record<string, any>) => void;
    ActionButton?: ReactElement | null;
    isStatistics?: boolean;
    headers?: Header[];
    setHeaders?: (value: Header[]) => void;
};

const TableFilterPanelStatistics: FC<TableFilterPanelStatisticsProps> = ({
    rowDense = thickRow,
    setRowDense = () => {},
    loading = false,
    rows = [],
    tableActions = {},
    request = {},
    setRequest = () => {},
    ActionButton = null,
    isStatistics = false,
    headers = [],
    setHeaders = () => {},
}) => (
    <Box
        sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: {xs: "column", md: "row"},
            mb: {xs: "1rem", md: ".75rem"},
            opacity: loading ? ".5" : "1",
            pointerEvents: loading ? "none" : "auto",
            borderRadius: ".25rem",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            pt: ".5rem",
        }}
    >
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: ".5rem",
                width: "100%",
                alignItems: "flex-start",
            }}
        >
            {tableActions.period ? (
                <>
                    <Period setRequest={setRequest} request={request} isStatistics={isStatistics} />
                    <Divider sx={{width: "100%"}} />
                </>
            ) : (
                <Box sx={{ display: "flex" }}>
                    <Box
                        sx={{
                            display: "flex",
                            gap: "12px",
                            alignItems: "center",
                            p: "6px 8px",
                            fontWeight: 500,
                        }}
                    >
                        <PeriodIcon />
                        Period
                    </Box>
                    <DatePickerCustom
                        openTo="month"
                        views={["year", "month"]}
                        value={request.date}
                        onChange={newValue => handleChangeField("date", newValue, request, setRequest)}
                        style={{ backgroundColor: "white", width: "224px" }}
                        disableBorders
                    />
                </Box>
            )}
            {tableActions.is_filter && <ColumnFilter headers={headers} setRequest={setRequest} request={request} badges />}
            {tableActions.is_report_filter && <ReportFilter setRequest={setRequest} request={request} badges />}
            <Divider sx={{width: "100%"}} />
            {tableActions.grouping && <Grouping setRequest={setRequest} request={request} badges />}
            <Divider sx={{width: "100%"}} />
            {tableActions.metrics && <Metrics setRequest={setRequest} request={request} badges />}
            <Box sx={{ display: "flex", gap: "24px" }}>
                {tableActions.is_column_selection && <ColumnSelection headers={headers} setHeaders={setHeaders} />}
                {tableActions.row_dense && rows.length > 0 && <DensePadding setRowDense={setRowDense} rowDense={rowDense} />}
            </Box>
        </Box>
        {ActionButton}
    </Box>
);

export default TableFilterPanelStatistics;
