import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { ActionIconType, TableDataType } from "../types";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/downloadIcon.svg";
import { ReactComponent as UploadIcon } from "../../../assets/icons/uploadIcon.svg";
import { ReactComponent as ConfirmIcon } from "../../../assets/icons/confirmIcon.svg";
import { ReactComponent as DeclineIcon } from "../../../assets/icons/declineIcon.svg";

export const sticky = {
    position: "sticky !important",
    right: 0,
    zIndex: 111,
};

export const defaultCellStyle = {
    padding: "0",
    background: "inherit",
    whiteSpace: "nowrap",
    position: "relative",
};

export const pinnedColumn = {
    position: "sticky",
    left: 0,
    zIndex: 1,
};

export const thickRow = 52;

export const thinRow = 42;

export const actionIcons: ActionIconType = {
    edit: <EditIcon />,
    delete: <DeleteIcon />,
    download: <DownloadIcon />,
    upload: <UploadIcon />,
    confirm: <ConfirmIcon />,
    decline: <DeclineIcon />,
    view: <MoreHorizIcon />,
    savings: <AccountBalanceWalletIcon />,
};

export const initialTableData: TableDataType = {
    headers: [],
    rows: [],
    table_name: "",
    all_counts: 0,
    page: null,
    size: null,
    table_actions: {
        add: false,
        is_column_selection: true,
        is_filter: true,
        row_actions: false,
        view: false,
    },
};

export type StatusColorsType = {
    [key: string]: {
        color: string;
        backgroundColor: string;
    };
};

export const StatusColors: StatusColorsType = {
    wait: {
        color: "var(--main-purple-color)",
        backgroundColor: "var(--main-purple-hover-color)",
    },
    review: {
        color: "#F34E4E",
        backgroundColor: "rgba(243, 78, 78, 0.10)",
    },
    in_progress: {
        color: "#F49A12",
        backgroundColor: "rgba(244, 154, 18, 0.10)",
    },
    problem_solving: {
        color: "#62D0E6",
        backgroundColor: "rgba(98, 208, 230, 0.10)",
    },
    complete: {
        color: "#66D670",
        backgroundColor: "rgba(102, 214, 112, 0.12)",
    },
    savings: {
        color: "#66D670",
        backgroundColor: "rgba(102, 214, 112, 0.12)",
    },
};
