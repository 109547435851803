import { Box, MenuItem, TextField } from "@mui/material";
import React from "react";

interface Option {
    name: string;
    value: string | number;
}
interface Props {
    value: string | number | boolean | null | undefined;
    label: string;
    style?: React.CSSProperties;
    items: Option[] | string[];
    readOnly?: boolean;
    onChange?: (value: string) => void;
    defaultValue?: string | number | boolean | null | undefined;
    icon: React.ReactNode;
}
const IconSelectInputFieldTransparent = ({ value = "", label, style = {}, items, onChange = () => {}, readOnly = false, defaultValue = null, icon }: Props) => {

    const defaultStyle = {
        minWidth: 100,
        m: 0,
    };

    return (
        <Box sx={{ display: "flex", alignItems: "center", p: "0 8px" }}>
            {icon}
            <TextField
                id={`outlined-select-${label}`}
                select
                value={value || defaultValue}
                sx={{
                    ...defaultStyle,
                    ...style,
                }}
                color="warning"
                InputLabelProps={{
                    color: "primary",
                }}
                variant="standard"
                InputProps={{
                    disableUnderline: true,
                    readOnly,
                    sx: {
                        borderRadius: "0",
                        border: "none",
                    },
                }}
                onChange={e => (e.target.value === "clear" ? onChange("") : onChange(e.target.value))}
            >
                <MenuItem key="clear" value="clear">
                    Clear selection
                </MenuItem>
                {items.map((option: Option | string, index: number) => {
                    let value = typeof option === "object" ? option.value : option;
                    const name = typeof option === "object" ? option.name : option;
                    if (!value) {
                        value = name;
                    }
                    return (
                        <MenuItem key={index} value={value as string | number}>
                            {name}
                        </MenuItem>
                    );
                })}
            </TextField>
        </Box>
    );
};

export default IconSelectInputFieldTransparent;
