import axios from "axios";
import {BASE_URL} from "../../../services/constants";

export const getUsersTable = async () => {
    try {
        const res = await axios.get("/user/list/");
        return await res.data;
    } catch (err) {
        throw new Error("Error getRelatedSearchesTable");
    }
}

export const getUser = async (id: string) => {
    try {
        const res = await axios.get(`${BASE_URL}/user/${id}/details/`);
        return res.data;
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
}

export const postUser = async (data: any, url: string) => {
    try {
        const res = await axios.post(url, data);
        return res.data;
    } catch (err: any) {
        throw new Error(err.response.data.message);
    }
}